import React from "react";
import { Link } from "gatsby"
import Layout from '../components/Layout';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
        <h1>Sivua ei löytynyt! Error 404</h1>
        <Link to="/">Etusivulle</Link>.
    </Layout>
  )
}

export default NotFoundPage;
